import React from 'react';
// import {NavLink} from "react-router-dom";
import RolesLinks from "../components/rolesLinks";

const Apply = ({RolesList}) => {
  return (
    <section className="apply">
      <div className="content column">
        <h1>How does the application process work?</h1>

        <div className="column-wrapper">
          <div>
            <p>Platform aims to create opportunities for people with little or no experience of the creative industries, so we will prioritise applicants with less than six month’s relevant work experience.</p>
          </div>
          <div>
            <p>To apply, please click here:</p>
            <a className="button" rel="noopener noreferrer" href="https://www.liftfutures.london/our-work/hkx-platform-x-lift" target="_blank"><span>Apply</span></a>
          </div>
        </div>

        <h2>Key dates</h2>
        <ul>
          <li><p>Applications: Open 27 March and close on Friday 14 April at 17:00</p></li>
          <li><p>Insight session: Thursday 6 April at 10:00 <a rel="noopener noreferrer" target="_blank" href="https://www.eventbrite.co.uk/e/hkx-platform-x-lift-insight-session-tickets-597651881867">Click here</a></p></li>
          <li><p>Screening: Monday 17 April - Wednesday 19 April (Candidate Screening)</p></li>
          <li><p>Interviews: Thursday 20 April - Monday 24 April (Havas Interviews)</p></li>
        </ul>

        <p>As part of the screening process, successful candidates will be invited to an informal chat with a recruitment partner before the offer of an interview with the employer at Havas</p>
        
        <h3>Current Jobs</h3>
        <p>We are seeking applications for the roles below.</p>

        {/* 
          <h4>Creative</h4>
          <p>Do you have a natural creative flair, brimming with creative ideas? Do you enjoy solving problems and coming up with new ways of doing things? As part of a creative team, you’ll learn to generate buoyant creative copy and visuals for a range of brands. You’ll be bursting at the seams with ideas and happy working on briefs both big and small. Whatever brief you get, you’ll need to make sure that you produce high quality, accurate work, which means design/strategy that’s appropriate for the client’s brand.  Click here to read more (link to JD sent via Teams)</p>
          <h4>Marketing and New Business</h4>
          <p>You’re going to love the hustle as well as the bustle. Move at pace, get things done. The internal New Business and Marketing team sits at the heart of our Havas Creative Group and is one of the most dynamic, fast paced and rewarding departments. From helping in the pitch process to win the agency new clients, through to supporting on the production and logistics of internal and external events, and everything in between, there is never a dull moment. Click here to read more (link to JD sent via Teams)</p>
          <h4>Account Management</h4>
          <p>Are you a real people person? Do you love meeting new people and building relationships? Your friends would describe you as being great with people, organised and you love to make things happen! Click here to read more (link to JD sent via Teams)</p>
        */}
      </div>

      <div className="cta-wrapper">
        <RolesLinks limited={["account-management","client-planning","insights","junior-tech-support","paid-social","content-creator","people-assistant","audio-vision","pr-assistant",]} RolesList={RolesList}/>
      </div>
    </section>
  )
}


export default Apply
