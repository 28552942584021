import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class Footer extends Component {

  render() {
    return (
      <footer>

        <div className="footer__social">
          <ul>
            <li>
              <a rel="noopener noreferrer" href="https://www.instagram.com/hkxplatform/" target="_blank">Instagram</a>
            </li>
            <li>
              <a rel="noopener noreferrer" href="https://twitter.com/havas_uk/" target="_blank">Twitter</a>
            </li>
          </ul>
        </div>
        <div className="footer__contact">
          <div className="contact">
            <span>Contact us on email <a href="mailto:hkxplatform@havas.com" rel="noopener noreferrer" target="_blank">hkxplatform@havas.com</a></span>
          </div>
          <div className="copy">
            <NavLink to="/privacy"><span>Privacy Policy</span></NavLink>
            <NavLink to="/terms"><span>Terms of use</span></NavLink>
            <NavLink to="/cookies"><span>Cookie Policy</span></NavLink>
            <span>&copy; 2019, HKX PLATFORM</span>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
