import React from "react";
import RolesLinks from "../components/rolesLinks";
// import Carousel from "../components/carousel";

const Role = ({roleDetails, RolesList}) => {
  return (
    <section className="role">
      <div className={`content ${roleDetails.jobSlug}`}>
        <div className="role-wrapper">
          <h1>{roleDetails.jobType}</h1>
          <p>{roleDetails.jobDescription}</p>
          <p className="jobdescription-extended">{roleDetails.jobDescriptionExtended}</p>
        </div>
       </div>
      <div className="sub-content">
        <h2>Find out about other roles</h2>
      </div>

      <div className="cta-wrapper">
        <RolesLinks RolesList={RolesList} CurrentRole={roleDetails.jobSlug}/>
      </div>
    </section>
  );
};

export default Role;
