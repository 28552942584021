import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class Postfail extends Component {

  render() {
    
    return (
      <section className="postfail">
        <div className="content">
          <h1>Sorry, Something went wrong</h1>

          <p>Please try again!</p>

          <div className="apply-button">
            <NavLink to={"/application"} className="button">Apply now</NavLink>
          </div>
        </div>
      </section>
    )
  }
}

export default Postfail;