import React, {Component} from 'react';

class Accordion extends Component {

  state = {open: false, maxHeight: 0};

  toggleOpen = (e) => {
    e.preventDefault();
    const currentState = this.state.active;
    const parentNode = e.target.parentNode.parentNode.querySelector('.accordion-text');
    let maxHeight = 0;

    console.log(parentNode)

    if (parentNode) {
      maxHeight = parentNode.scrollHeight + 34;
    }
    this.setState({ active: !currentState, maxHeight: !currentState ? maxHeight : 0 });
  }

  render() {
    return (
      <li className={`accordion ${this.props.EmployeeList ? this.props.EmployeeList.employeesSlug : ''} ${this.state.active ? 'open': ''}`}>
        {this.props.FaqList ? 
          <div>
            <div onClick={this.toggleOpen} className="accordion-block">
              <a href="#">{this.props.FaqList.question}</a>
            </div>
            <div className="accordion-text" style={{ maxHeight: this.state.maxHeight }}>
              <div dangerouslySetInnerHTML={{ __html: this.props.FaqList.answer }} />      
            </div> 
          </div>
        : 
          <div>
            <div onClick={this.toggleOpen} className="accordion-block">
              <a href="#">{this.props.EmployeeList.employeesType}</a>
            </div> 
            <div className="accordion-list">
              <div className="accordion-wrapper">
                {this.props.EmployeeList.employees.map((employee, index) => (
                  <div key={index} className="accordion-element">
                    {/* Image taken out, may be added in later */}
                    {/* <img alt={employee.employeeName} src={employee.employeeImage}/> */}
                    <span className="employee-name">{employee.employeeName}</span>
                    <span className="employee-position">{employee.employeePosition}</span>
                    {employee.employeeLinkedin ? 
                      <span className="employee-linkedin">
                        <a rel="noopener noreferrer" href={employee.employeeLinkedin} target="_blank">LinkedIn</a>
                      </span>
                    : ''}
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      </li>
    )
  }
}

export default Accordion