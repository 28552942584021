import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

import Navigation from "./navigation";

class Header extends Component {

  render() {
    return (
      <header className={`${this.props.isSticky ? '': 'isSticky'}`}>
        <NavLink className="logo" to="/">
          <img title="HKX Platform" alt="HKX Platform" src="/assets/images/platform-logo.svg"/>
        </NavLink>
        <Navigation/>
      </header>
    )
  }
}

export default Header