import React from "react";
import RolesLinks from "../components/rolesLinks";

const Roles = ({RolesList}) => {
  return (
    <section className="roles">
      <div className="content">
        <h1>About the roles</h1>
        
        <p>
        To bring a great idea to life takes teamwork. That’s why every campaign that comes out of Havas is the product of a talented team with a perfect mix of different skills and personalities. It’s amazing what you can accomplish when you stop caring who gets the credit. Spend some time learning about the different disciplines within our agency and what roles the Platform programme covers.
        </p>
      </div>
      <div className="sub-content">
        <h2>Find out more about each discipline</h2>
      </div>
      <div className="cta-wrapper">
        <RolesLinks hide={["account-management","client-planning","insights","junior-tech-support","paid-social","content-creator","people-assistant","audio-vision","pr-assistant",]}  RolesList={RolesList}/>
      </div>
    </section>
  );
};

export default Roles;