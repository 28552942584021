import React, { Component } from 'react';
import {Route, Switch, BrowserRouter as Router, withRouter } from "react-router-dom";
import UAParser from 'ua-parser-js';

import RolesList from "./data/generateContent/roles.json";

import Header from "./components/header";
import Footer from "./components/footer";
import CookiesAlert from "./components/cookies-alert";

import Home from "./pages/home";
import About from "./pages/about";
import Roles from "./pages/roles";
import Role from "./pages/role";
import Faq from "./pages/faq";
import Apply from "./pages/apply";
import Application from "./pages/application";
import Thankyou from "./pages/thankyou";
import Postfail from "./pages/postfail";
import Error from "./pages/error";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Cookies from "./pages/cookies";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children
  }
}

const ScrollToTopRouter = withRouter(ScrollToTop);


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {isSticky: true};
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.navScrollClass);
    var parser = new UAParser();
    var result = parser.getResult();

    document.body.className += result.browser.name;
  }

  navScrollClass = () => {
    const Ypos = window.scrollY;
    Ypos <= 120 ? this.setState({ isSticky: true }) : this.setState({ isSticky: false });
  }

  render() {

    const RolePathDirect = ({ match }) => {
      const rolePage = RolesList.map((role, index) => {
        if (role.jobSlug === match.params.role) {
          return (
            <Role key={index} roleDetails={role} RolesList={RolesList}/>
          );
        }
      });

      return rolePage;
    }

    return (
      <Router>
        <ScrollToTopRouter>
        <div>
          <Header isSticky={this.state.isSticky}/>
          <div className="contentWrapper">
            <Switch>
              <Route exact path="/" render={(props) => <Home {...props} RolesList={RolesList}/>}/>
              <Route exact path="/about" component={About}/>
              <Route exact path="/roles" render={(props) => <Roles {...props} RolesList={RolesList}/>}/>
              <Route exact path="/roles/:role" render={RolePathDirect}/>
              <Route exact path="/faq" render={Faq}/>
              <Route exact path="/apply" render={(props) => <Apply {...props} RolesList={RolesList}/>}/>
              <Route exact path="/application" component={Application}/>
              <Route exact path="/thankyou" component={Thankyou}/>
              <Route exact path="/postfail" component={Postfail}/>
              <Route exact path="/privacy" component={Privacy}/>
              <Route exact path="/terms" component={Terms}/>
              <Route exact path="/cookies" component={Cookies}/>
              <Route component={Error}/>
            </Switch>
          </div>
          <Footer/>
          <CookiesAlert/>
        </div>
        </ScrollToTopRouter>
      </Router>
    );
  }
}

export default App;
