import React, {Component} from 'react';

class Privacy extends Component {

  render() {
    
    return (
      <section className="privacy">
        <div className="content">
          <h1>Privacy Policy</h1>

          <p>HKX Platform is committed to ensuring that your privacy is protected. This Policy explains when and why we collect personal information about you, how we use it, the conditions under which we may disclose it to others and how we keep it secure.</p>

          <p>HKX Platform may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>

          <p>Any questions regarding this Policy and our privacy practices should be sent by email to <a rel="noopener noreferrer" href="mailto:data.protectionuk@havas.com" target="_blank">data.protectionuk@havas.com</a> or by writing to Data Protection Officer, The HKX Building, 3 Pancras Square, N1C 4AG, London, UK</p>

          <h2>Who are we?</h2>
          <p>We’re a group of agencies that are part of Havas Creative and Media Groups, a global network of industry leading media communications agencies. We’re a registered company with the numbers to prove it, and 3 Pancras Square, London, N1C 4AG is where we call home.</p>

          <h2>How do we collect information about you?</h2>
          <p><strong>Directly from you.</strong> For example, when you submit your details directly on our website.  This could include your name, email address and mobile number within the application form.</p>

          <p><strong>Email response information.</strong> If your application is unsuccessful your data will be removed from our database within 90 days. If your application is successful we will contact you using either email or phone number inviting you to an assessment day as a form of potential recruitment. All emails will be solely recruitment related. You will have a chance to unsubscribe at each interaction with HKX Platform, to do so you must email the contact details on this site asking to be unsubscribed.</p>
          <p>
            <strong>Online Tracking.</strong> We use technologies, such as cookies, to customise content and advertising, to provide social media features and to analyse traffic to the site. We also share information about your use of our site with our trusted social media, advertising and analytics partners.
            <p>More information: <a rel="noopener noreferrer" href="https://developers.facebook.com/docs/privacy/" target="_blank">https://developers.facebook.com/docs/privacy/</a></p>
          </p>
          <p>If you want to see the data we hold about you simply email <a rel="noopener noreferrer" href="mailto:data.protectionuk@havas.com" target="_blank">data.protectionuk@havas.com</a></p>

          <h2>How is your information used?</h2>

          <p>HKX Platform respects the rights of each individual, employee, candidate or customer to have his privacy and personal data protected. </p>
          <p>HKX Platform will observe the following principles when processing your personal information: </p>
          <ol>
            <li>Processing your personal information fairly, lawfully and in a transparent manner;</li>
            <li>Collecting your personal information for specified, legitimate purposes and not processing further in ways incompatible with those purposes; </li>
            <li>Collecting your personal information which is relevant to and not excessive for the purposes for which it is collected and used.  We may render information anonymous when feasible and appropriate, depending on the nature of the data and the risks associated with the intended uses;</li>
            <li>Maintaining your personal information accurate, and where necessary, kept up-to-date. We will take reasonable steps to rectify or delete data that is inaccurate or incomplete; </li>
            <li>Keeping your personal information only as long as it is necessary for the purposes for which it was collected and processed; </li>
            <li>Processing your personal information in accordance with the individual’s legal rights;</li>
            <li>Taking appropriate technical, physical and organizational measures to keep your data safe and prevent unauthorized access, unlawful processing, and unauthorized or accidental loss, destruction, or damage to personal information; </li>
            <li>Processing your personal information based on the following legal basis: 
              <ul>
                <li>- You have unambiguously given your consent; or </li>
                <li>- The processing is necessary for the performance of a contract to which you are party or in order to take steps at your request prior to entering into a contract; or </li>
                <li>- The processing is necessary for compliance with a legal obligation to which HKX Platform is subject; or </li>
                <li>- The processing is necessary in order to protect your vital interests or the vital interest  of another natural personal; </li>
                <li>- The processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in HKX Platform; or</li>
                <li> - The processing is necessary for the purposes of the legitimate interests pursued by HKX Platform or by a third party except where such interests are overridden by your interests or your fundamental rights and freedoms.</li>
              </ul>
            </li>
          </ol>

          <p>Information submitted by you is stored on secure servers. If you want to see the data we hold about you on this database simply email <a rel="noopener noreferrer" href="mailto:data.protectionuk@havas.com" target="_blank">data.protectionuk@havas.com</a></p>

          <p>We review our retention periods for personal information on a regular basis. We will hold your personal information on our systems for as long as is necessary for the relevant activity, or as long as is set out in any relevant contract you hold with us.</p>

          <h2>How we use cookies</h2>
          <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. </p>
          
          <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. </p>

          <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>

          <h2>Links to third-party websites</h2>

          <p>HKX Platform websites or services may provide links to third-party applications, products, services, or websites for your convenience and information. If you access those links, you will leave the HKX Platform website. HKX Platform does not control those third-party websites or their privacy practices, which may differ from HKX Platform practices. We do not endorse or make any representations about third-party websites. The personal information you choose to provide to or that is collected by these third parties is not covered by the HKX Platform Data Protection Policy. We encourage you to review the privacy policy of any website you interact with before allowing the collection and use of your personal information. </p>

          <p>We may also provide social media links that enable you to share information with your social networks and to interact with HKX Platform on various social media sites. Your use of these links may result in the collection or sharing of information about you, depending on the feature. We encourage you to review the privacy policies and settings on the social media sites with which you interact to make sure you understand the information that may be collected, used, and shared by those sites. </p>

          <p>If you post, comment, indicate interest, or share personal information, including photographs, to any public forum, social network, blog, or other such forum, please be aware that any personal information you submit can be read, viewed, collected, or used by other users of these forums, and could be used to contact you, send you unsolicited messages, or for purposes that neither you nor HKX Platform have control over HKX Platform is not responsible for the personal information you choose to submit in these forums. </p>

          <h2>Who has access to your information?</h2>

          <p>If you do provide personal information to us, either directly or through a reseller or other business partner, we will:</p>

          <ol>
            <li>take commercially reasonable precautions to protect the information from loss, misuse and unauthorized access, disclosure, alteration and destruction;</li>
            <li>not use or disclose the information except: 
              <ul>
                <li>> we may pass your information to our third party service providers, agents, subcontractors and other associated organizations’ for the purposes of completing tasks and providing services to you on our behalf.  However, when we use third party service providers, we disclose only the personal information that is necessary to deliver the service and we have a contract in place that requires them to keep your information secure and not to use it for their own direct marketing purposes.</li>
                <li>> in other ways described in this Data Protection policy or to which you have otherwise consented; </li>
                <li>> in the aggregate with other information in such a way so that your identity cannot reasonably be determined (for example, statistical compilations); </li>
                <li>> as required by law, for example, in response to a subpoena or search warrant; </li>
                <li>> to outside auditors who have agreed to keep the information confidential; </li>
                <li>> as necessary to enforce the Terms of Service; </li>
                <li>> as necessary to protect the rights, safety, or property of HKX Platform, its users, or others; this may include (for example) exchanging information with other organizations or public authorities for identification, fraud protection and/or risk reduction</li>
              </ul>
            </li>
          </ol>
          <p>For more information please email <a rel="noopener noreferrer" href="mailto:data.protectionuk@havas.com" target="_blank">data.protectionuk@havas.com</a></p>

          <h2>Your consent</h2>
          <p>By providing us with your personal information and/or using the websites and/or entering into a contract with us, you consent to our processing your Personal data for the above purposes. You also consent to our transferring your information to countries or jurisdictions which do not provide the same level of data protection as the EU, if necessary for the above purposes. If we do make such a transfer, we will provide appropriate safeguards to ensure that your information is duly protected. If you provide us with information about another person, you confirm that they have appointed you to act for them, to consent to the processing of their Personal data including sensitive personal data and that you have informed them of our identity and the purposes (as set out above) for which their Personal data will be processed. We do not process Personal data of minors under 16 without the express consent of their parents or legal representatives.</p>

          <h2>Your Data Protection rights </h2>
          <ul>
            <li>
              <span><strong>Right of access</strong></span> <br/>
              <p>As data subjects, you may inquire as to the nature of the personal data stored or processed about you by HKX Platform. You will be provided access to your Personal data regardless of the location of the data processing and storage. </p>
            </li>
            <li>
              <span><strong>Right to rectification</strong></span> <br/>
              <p>If your Personal data is inaccurate or incomplete, you may request that the data be amended. </p>
            </li>
            <li>
              <span><strong>Right to object </strong></span> <br/>
              <p>You have the right to object at any time to the processing of your Personal data for the purposes of HKX Platform legitimate interests or for direct marketing purposes.</p>
            </li>
            <li>
              <span><strong>Right to erasure</strong></span> <br/>
              <p>You can ask for the deletion of your Personal data when it is allowed by applicable data protection laws and regulations. </p>
            </li>
            <li>
              <span><strong>Right to restriction of processing</strong></span> <br/>
              <p>You have the right to require a restriction of the processing of your Personal data when it is authorized by applicable data protection laws and regulations. </p>
            </li>
            <li>
              <span><strong>Right to data portability </strong></span> <br/>
              <p>If you meet the conditions settled by the applicable data protection laws and regulation, you   have the right to receive a subset of your Personal data and to transmit them from HKX Platform to another data Controller. </p>
              <p>You can also require the direct transmission from HKX Platform to another Controller when it is technically feasible.</p>
            </li>
            <li>
              <span><strong>Right to lodge a complaint</strong></span> <br/>
              <p>You have the right to lodge complaint with the competent supervisory authority. All requests for access, rectification, limitation, erasure, restriction or portability of your Personal data, and any questions regarding this Data Protection Policy, may be sent to:  <a rel="noopener noreferrer" href="mailto:data.protectionuk@havas.com" target="_blank">data.protectionuk@havas.com</a></p>
            </li>
          </ul>

          <h2>Review of this Policy</h2>
          <p>We keep this Policy under regular review. This Policy was last updated in February 2019</p>
        </div>
      </section>
    )
  }
}

export default Privacy;