import React from "react";
import FAQS from '../data/generateContent/faq.json';

import Accordion from '../components/elements/accordion';

const Faq = () => {
  return (
    <section className="faq">
      <div className="content">
        <h1>Practical Questions</h1>
      </div>
      <ul className="accordions">
        {FAQS.map((faqList, index) => (
          <Accordion key={index} FaqList={faqList}/>
        ))}
      </ul>
    </section>
  );
};

export default Faq;