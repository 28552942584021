import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
// import Employees from '../data/generateContent/employees.json';
import Clients from '../data/generateContent/clients.json';

// import Accordion from '../components/elements/accordion';

class About extends Component {

  render() {

    return (
      <section className="about">
        <div className="content column">
          <h1>What is HKX x LIFT Platform?</h1>
          <div className="flex-wrapper">
            <div>
              <p>HKX Platform is a unique placement experience. It’s a full-time 6-month, in-depth learning experience in Partnership with LIFT, for people wanting to get into the advertising, communication, and media industry. HKX Platform is for anyone who likes brands, culture, and communication. You’ll be a part of the real team, working on real briefs, doing real work, for real clients.</p>
              <img src="./assets/images/content/Image_1.png" alt="HKX Platform London Office" className='w-full' />
              <p>In HKX, our London office, 24 agencies share the same office space, with the aim to increase collaboration and communication between all of our people and agencies. HKX Platform started in our London office (hence the name!) but "HKX" has quickly become a shortcut for our entire UK Village, and Platform is open to those outside of London too.</p>
              <img src="./assets/images/content/Image_2.png" alt="HKX Platform Living Wage" className='w-full' />
              <p>Not only will you be in an awesome job paying London Living Wage for 6 months, learning from the best in our industry and doing amazing work, you will also receive additional employability support to help boost your employment prospects in the future.</p>
              <img src="./assets/images/content/Image_3.png" alt="HKX Platform Entrepreneurial" className='w-full' />
              <p>We are looking for entrepreneurial, innovative, accountable, committed, and collaborative candidates. You don’t need any previous experience or specific skills: we’re more interested in what you will bring, not what you can already do.</p>
              <span>If you have any questions visit our <NavLink to="/faq">FAQ</NavLink> section.</span>
            </div>
            <div class="lift-branding">
              <h2>LIFT <a href="https://www.liftfutures.london/our-work/hkx-platform-x-lift" rel="noopener noreferrer" target="_blank"><img src="./assets/images/logos/lift.png" alt="HKX Platform x Lift" width="100" /></a></h2>
              <p>LIFT work with businesses across four London boroughs (Camden, Hackney, Islington and Tower Hamlets) to connect them with local communities and ensure residents have access to good quality jobs that can build a lifetime of opportunities for generations to come.</p>
              <p><a href="https://www.liftfutures.london/our-work/hkx-platform-x-lift" rel="noopener noreferrer" target="_blank">Find out more</a></p>
            </div>
          </div>

        </div>

        {/* Temp remove as this may be wanted later... */}
        {/*
        <div className="sub-content">
          <h2>Who's who at Havas</h2>
        </div>

        <ul className="accordions">
          {Employees.map((employeeList, index) => (
            <Accordion key={index} EmployeeList={employeeList}/>
          ))}
        </ul>
        */}

        <div className="bottom-sub-content">
          <h2>Work with clients like these:</h2>

          <ul className="client-list">
            {Clients.map((client, index) => (
              <li key={index}>
                <img alt={client.clientName} title={client.clientName} src={client.clientLogo}/>
              </li>
            ))}
          </ul>
        </div>
      </section>
    )
  }
}

export default About;
