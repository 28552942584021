import React, {Component} from 'react';
import CTA from "./elements/cta";

class Roles extends Component {

  render() {
    const { CurrentRole, hide, limited, RolesList } = this.props;
    let limit = false;
    let hidden = false;

    // Toggle limited version of CTA
    if (limited) {
      limit = true
    }
  
    // Hide specific CTA's
    if (hide) {
      hidden = true
    }

    return (
      <React.Fragment>
        {RolesList.map((role, index) => {
          if (role.jobSlug !== CurrentRole) {
            if(limit && !limited.includes(role.jobSlug) || hidden && hide.includes(role.jobSlug)) {
              return null
            }
            return <CTA key={index} limit={limit} role={role} />
          }
          return null;
        })}
      </React.Fragment>
    )
  }
}

export default Roles