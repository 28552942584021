import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

const CTAComponent = ({ children, className, jobSlug, limit }) => {
  if(limit) {
    return (
      <div className={className}>
        {children}
      </div>
    )
  }
  return <NavLink to={jobSlug} className={className}>{children}</NavLink>
}
class Cta extends Component {
  render() {
    const { role } = this.props;

    return (
      <div className={`cta ${role.jobSlug} ${this.props.limit ? 'is-limited' : ''}`}>
        <CTAComponent limit={this.props.limit} className={`cta-link`} jobSlug={"/roles/" + role.jobSlug} >
          <div className={`cta-role ${role.jobSlug}`}>
            <ul>
              {role.jobWords.map((word, index) => (
                <li key={index}><span>{word}</span></li>
              ))}
              <li className="mobile-role"><span>{role.jobMobileType}</span></li>
            </ul>
          </div>

          {this.props.limit ? (
            <div class="cta-description">
              <p>{role.jobDescriptionShort}</p>
              {role.jobLink ? (<p><a href={role.jobLink} rel="noopener noreferrer" target="_blank">Click here to read more</a></p>) : ''}
            </div>
          ) : ''}
        </CTAComponent>
      </div>
    )
  }
}

export default Cta
