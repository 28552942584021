import React, {Component} from 'react';

class Terms extends Component {

  render() {
    
    return (
      <section className="terms">
        <div className="content">
          <h1>Use of this website</h1>
          
          <p>
            The access to and use of the Website implies Your full acceptance and undertaking to abide
            entirely by You the Terms and Conditions.
          </p>
          <p>
            You should read all the Terms and Conditions prior to using the Website. You should also save
            and/or print out a copy of these Terms and Conditions for future reference. However, please note
            that we may change our Terms and Conditions from time to time, therefore we recommend You
            reading the Terms and Conditions each time You access the Website.
          </p>
          <p>
            The revised Terms and Conditions will be available via the Website. You will be deemed to have
            accepted any changes to the Terms and Conditions after You have been notified of the changes
            on our Website home page and You continue to access or use the Website.
          </p>
          <p>
            You are not permitted to use, or cause others to use, any automated system or software to
            extract content or data from our Website for commercial purposes.
          </p>
          <p>If You do not agree to these Terms and Conditions, You should not use the Website.</p>
          <p>
            Access to our Website is permitted on a temporary basis. We update our Website regularly and
            so may change the content at any time without notice to You. We reserve the right to withdraw,
            vary or suspend the service at any time without notice.
          </p>
          <p>
            You are responsible for making all arrangements necessary to access this Website. You are also
            responsible for ensuring that all persons accessing our Website through Your internet connection
            are aware of these Terms and Conditions.
          </p>
          <p>
            Please note that use of our Website is subject to Your computer and/or portable device
            complying with our minimum standard technical specification and compatibility notice. You are
            advised to check this specification to ensure that Your computer and/or portable device is
            compatible with our products and services and we shall not be liable for any failure arising in the
            Website which arises from incompatibility (including, without limitation, minimum storage and
            memory requirements from time to time).
          </p>
          <p>
            You agree not to use the Website for fraudulent purposes, and not to perform any conduct that
            may damage the image, interests and rights of Havas Media or third parties. You also agree not
            to take any action in order to damage, disable or overburden the Website, or hinder, in any way,
            the normal use and operation.
          </p>
          <p>
            We inform You that in the event of breach of the contents of this Terms and Conditions, Privacy
            Policy or Cookies Policy, we reserve the right to limit, suspend or terminate Your access to the
            Website, taking any technical measures necessary for that purpose.
          </p>
        </div>
      </section>
    )
  }
}

export default Terms;