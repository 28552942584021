import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class Navigation extends Component {

  state = {
    active: false,
    position: 0
  };

  toggleClass = () => {
    const currentState = this.state.active;
    const windowWidth = window.innerWidth;
    const body = document.querySelector('body');
    let scrollPos = window.scrollY;

    if (windowWidth < 768) {
      this.setState({ active: !currentState });

      if (body.classList.contains("mobile__menu--open")) {
        body.classList.remove('mobile__menu--open');
        window.scroll(0, this.state.position);
        body.classList.remove('mobileNav--fixed');
      } else {
        body.classList.add('mobile__menu--open');
        this.setState({position: scrollPos});
        if (scrollPos > 120) {
          body.classList.add('mobileNav--fixed');
        } else {
          body.classList.remove('mobileNav--fixed');
        }
      }
    }
  }

  closeMenu = () => {
    this.setState({active: false});
  }

  render() {
    return (
      <nav>
        <div className={`mobile-menu ${this.state.active ? 'open': ''}`} onClick={this.toggleClass}>
          <div className="burger">
            <span>Burger menu</span>
          </div>
        </div>
        <div className={`menu-wrapper ${this.state.active ? 'open': ''}`}>
          <ul>
            <div className="desktop-wrapper">
              <li><NavLink to="/" onClick={this.toggleClass}><span>01</span>Home</NavLink></li>
              <li><NavLink to="/about" onClick={this.toggleClass}><span>02</span>About</NavLink></li>
              <li><NavLink to="/roles" onClick={this.toggleClass}><span>03</span>The roles</NavLink></li>
              <li><NavLink to="/faq" onClick={this.toggleClass}><span>04</span>FAQ</NavLink></li>
              {/* <li><NavLink to="/apply" onClick={this.toggleClass}><span>05</span>Apply</NavLink></li> */}
            </div>
          </ul>
        </div>

        {/* <NavLink className="nav-apply" to="/apply" onClick={this.closeMenu}><span>Apply</span></NavLink> */}
      </nav>
    )
  }
}

export default Navigation
