import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class Home extends Component {

  render() {
    return (
      <section className="home">
        <div className="content">
          <h1>
            HKX Platform.
            <br/>
            A place to
            <br/>
            get Going
          </h1>

          <div className="roles-text-animate">
            <ul>
              <li className="account-management">For Account management</li>
              <li className="creative">For Creative</li>
              <li className="data-analytics">For Data Analytics</li>
              <li className="digital-marketing">For Digital Marketing</li>
              <li className="design">For Design</li>
              <li className="marketing-new-business">For Marketing and New Business</li>
              <li className="management-consultancy">For Management Consultancy</li>
              <li className="production">For Production</li>
              <li className="project-management">For Project Management</li>
              <li className="public-relations">For Public Relations</li>
              <li className="social-media">For Social Media</li>
              <li className="strategy">For Strategy</li>
              <li className="human-resources">For Human Resources</li>
            </ul>
          </div>

          <div className="roles-image-animate">
            <ul>
              <li className="account-management"><img alt="Account management" src="./assets/images/patterns/Account_Management_pattern-2.svg"/></li>
              <li className="creative"><img alt="Creative" src="./assets/images/patterns/Creative_pattern-2.svg"/></li>
              <li className="data-analytics"><img alt="Data Analytics" src="./assets/images/patterns/Creative_pattern-2.svg"/></li>
              <li className="digital-marketing"><img alt="Digital Marketing" src="./assets/images/patterns/Creative_pattern-2.svg"/></li>
              <li className="design"><img alt="Design" src="./assets/images/patterns/Design_pattern-2.svg"/></li>
              <li className="marketing-new-business"><img alt="Marketing and New Business" src="./assets/images/patterns/Design_pattern-2.svg"/></li>
              <li className="management-consultancy"><img alt="Management Consultancy" src="./assets/images/patterns/Design_pattern-2.svg"/></li>
              <li className="production"><img alt="Production" src="./assets/images/patterns/Production_pattern-2.svg"/></li>
              <li className="project-management"><img alt="Project Management" src="./assets/images/patterns/Production_pattern-2.svg"/></li>
              <li className="public-relations"><img alt="Public Relations" src="./assets/images/patterns/Production_pattern-2.svg"/></li>
              <li className="social-media"><img alt="Social Media" src="./assets/images/patterns/Production_pattern-2.svg"/></li>
              <li className="strategy"><img alt="Strategy" src="./assets/images/patterns/Strategy_pattern-2.svg"/></li>
              <li className="human-resources"><img alt="Human Resources" src="./assets/images/patterns/Strategy_pattern-2.svg"/></li>
            </ul>
          </div>

        </div>

        <div className="vimeo-wrapper">
          <div className="vimeo-wrapper__inner">
          <iframe src="https://player.vimeo.com/video/811252295?h=1e2133188f&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>

        <div className="home-links">
          <NavLink className="home-cta" to="/about"><span>About the Programme</span></NavLink>

          <NavLink className="home-cta" to="/roles"><span>Find out about Different Disciplines</span></NavLink>
        </div>
      </section>
    )
  }
}

export default Home
