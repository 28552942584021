import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class CookiesAlert extends Component {

  state = {
    acceptCookie: false
  }

  componentWillMount() {
    if (localStorage.getItem("acceptedCookie") === "hasSavedCookie") {
      this.setState({acceptCookie: true});
    }
  }

  acceptCookies = (e) => {
    e.preventDefault();
    this.setState({acceptCookie: true});
    localStorage.setItem("acceptedCookie", "hasSavedCookie");
  }

  render() {
    return (
      <div className={`cookies-alert ${this.state.acceptCookie ? 'accepted' : ''}`}>
        <div className="cookie-message">
          <span>This site uses cookies to personalise content, to provide social media features and to analyse traffic. <NavLink to="/cookies">Learn more </NavLink></span>
        </div>
        <div className="cookie-accept-container">
          <a className="cookie-accept-btn" href="#" onClick={this.acceptCookies}>Accept</a>
        </div>
      </div>
    )
  }
}

export default CookiesAlert;