import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class Cookies extends Component {

  render() {
    
    return (
      <section className="coookies">
        <div className="content">
          <h1>Cookies Policy</h1>

          <p>
            When you visit our website <strong>hkxplatform.co.uk</strong>, some cookies or other tracking technologies may
            be stored on your device (computer, tablet or smartphone). This Cookie Policy contains
            information on what cookies are, the type of cookies used by Havas London, part of Havas
            Group, on the website hkxplatform.co.uk and the purpose of such use, and your choices
            regarding cookies.
          </p>
          <p>
            This Cookie Policy should be read together with Havas <NavLink to="/privacy">Data Protection Policy</NavLink> and our <NavLink to="/terms">Legal
            Notice and Terms of Use</NavLink>.
          </p>

          <h2>What are cookies and other tracking technologies?</h2>

          <p>
            Cookies are small text files which contain an amount of data generated when you browse on a
            website. They are installed in your internet browser. The other tracking technologies, such as
            web beacons, pixels or clear gifs, work similarly to cookies.
          </p>

          <p>
            Cookies and other tracking technologies are used to monitor your activity on a website by
            enabling us (through first party cookies) or thirds (through third party cookies) to collect
            information about how you use the website and enhance the way it works and your visit on our
            website.
          </p>

          <p>There are two types of cookies: session cookies and persistent cookies.</p>

          <ol>
            <li><strong>Session cookies</strong> are temporary and therefore automatically deleted from your device every time you close your browser;</li>
            <li><strong>Persistent cookies</strong> remain on your device for a period of time specified in the cookie and are activated every time you visit the website which installed it.</li>
          </ol>

          <h2>What type of cookies do we use and for which purpose?</h2>
          <p>When you browse on our website, we use the following types of cookies:</p>

          <h3>1. Strictly necessary cookies</h3>
          <p>
            These cookies are necessary for the proper operation of the services provided on the website.
            They are used to provide the basic functionalities of the website, like remembering information
            entered in a form or your language preference or allowing you to access your personal account.
            If you disable these cookies, you will no longer be able to use these features and the website
            might not work as efficiently as it should.
          </p>

          <h3>2. Performance cookies</h3>
          <p>
            These cookies are used to collect anonymous data for statistical purposes. They allow us to
            measure the audience on the website and analyze the way visitors browse the website (number
            of visitors on the website, number of visits per page, time spent on each page, areas where there
            are clicks, measure of the effectiveness of advertising…). They are also used to detect browser
            issues or any other difficulty. These cookies help us improve our website and make your
            browsing experience better.
          </p>

          <h3>3. Functional cookies</h3>
          <p>
            These cookies enable the website to provide enhanced functionality and personalisation. They
            may be set by us or by verified third party providers whose services we have added to our
            pages.
          </p>

          <h3>4. Third-party cookies</h3>
          <p>
            In some cases, we use third parties to manage our cookies. Third parties may also use their own
            cookies or other tracking technologies to collect information about your activity on the website, in
            particular to provide you with personalized advertisements on their own websites.
          </p>

          <p>
            Some <strong>social network cookies might also be installed on your device to allow you to
            interact</strong> on social networks, such as Facebook or Twitter.
          </p>

          <p>
            <strong>We are in no way involved on the implementation and functioning of such cookies and
            have no access or control overs such third-party cookies.</strong> These cookies are subject to their
            own cookie policies, which are accessible on each third-party website and to which we invite you
            to refer to. We are therefore not responsible for the data collected by these cookies and cannot
            provide you with further information regarding the nature of information collected by these third-
            party cookies.
          </p>

          <h2>Your choice regarding cookies</h2>

          <p>You have several options to control or limit how we or third parties use cookies:</p>

          <ul>
            <li>You can choose to delete all or some cookies from your device through your browser settings;</li>
            <li>You can choose to prevent cookies from being installed on your device through your browser settings.</li>
          </ul>

          <p>
            To learn more on how to manage cookies usage from your browser, please follow the following
            links for each browser:
          </p>

          <ul>
            <li><a rel="noopener noreferrer" target="_blank" href="https://support.microsoft.com/en-gb/help/278835/how-to-delete-cookie-files-in-internet-explorer">Internet Explorer</a></li>
            <li><a rel="noopener noreferrer" target="_blank" href="https://support.google.com/chrome/?hl=en&amp;answer=95647&amp;p=cpn_cookies#topic=7438008">Chrome</a></li>
            <li><a rel="noopener noreferrer" target="_blank" href="https://support.apple.com/kb/PH21411?locale=en_GB&viewlocale=en_US">Safari</a></li>
            <li><a rel="noopener noreferrer" target="_blank" href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies">Mozilla Firefox</a></li>
            <li><a rel="noopener noreferrer" target="_blank" href="https://help.opera.com/en/Linux/10.50/en/cookies.html">Opera</a></li>
          </ul>

          <p>
            Please note that if you choose to block or de-activate some or all cookies we use, our website
            might not work properly, and you might not be able to enjoy all the services and functionalities
            provided through the use of cookies.
          </p>

          <p>
            If you want to learn more about cookies and how to manage them, please visit the following
            websites: <a rel="noopener noreferrer" target="_blank" href="http://www.youronlinechoices.eu/">www.youronlinechoices.eu</a> and <a rel="noopener noreferrer" target="_blank" href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>.
            You may also modify the cookies on this website on the Cookies settings or (Privacy Preference
            Center) at the bottom of each page of Our website.
          </p>


          <h2>Contact us</h2>

          <p>
            If you have any question, comment or concern regarding this Cookie Policy and the way Havas
            uses cookies and other tracking technologies, please contact us at:
          </p>
          <p>
            HAVAS London Ltd <br/>
            The HKX Building <br/>
            3 Pancras Square <br/>
            London N1C 4AG <br/>
          </p>

          <p>
            Or send us an email at: <a rel="noopener noreferrer" href="mailto:tracey.barber@havas.com" target="_blank">tracey.barber@havas.com</a>
          </p>
        </div>
      </section>
    )
  }
}

export default Cookies;