import React, {Component} from "react";
import {NavLink} from "react-router-dom";

class Application extends Component {
  
  state = {
    eligible: true,
    name: null,
    email: null,
    contactNo: null,
    placement: null,
    dates: null,
    aboutYou: null,
    wishInvolved: null,
    whyComms: null,
    scheme: null
  };

  eligibleFalse = () => {
    this.setState({eligible: false});
  }

  eligibleTrue = () => {
    this.setState({eligible: true});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;

    for (const [key, value] of Object.entries(this.state)) {
      if(value === null || value === false){
        this.setState({[key] : false});

        valid = false;
      }
    }

    if(valid === false) {
      setTimeout(function(){ 
        document.querySelectorAll(".show-message")[0].scrollIntoView();
        window.scrollBy(0, -70);
      }, 50);
    }

    if(valid === true) {
      document.getElementById('applicationForm').submit();
    }
  }

  validator = (e) => {
    if (e.target.dataset.validator === "name") {
      this.validateName(e.target);
    }

    if (e.target.dataset.validator === "email") {
      this.validateEmail(e.target);
    }

    if (e.target.dataset.validator === "contactNo") {
      this.validateContactNo(e.target);
    }

    if (e.target.dataset.validator === "placement") {
      this.validatePlacement(e.target);
    }

    if (e.target.dataset.validator === "dates") {
      this.validateDate(e.target);
    }

    if (e.target.dataset.validator === "aboutYou") {
      this.validateAboutYou(e.target);
    }

    if (e.target.dataset.validator === "wishInvolved") {
      this.validateWishInvolved(e.target);
    }

    if (e.target.dataset.validator === "whyComms") {
      this.validateWhyComms(e.target);
    }

    if (e.target.dataset.validator === "schemes") {
      this.validateSchemes(e.target);
    }
  };

  validateName(el) {
    const string = el.value;
    string.length > 0 ? this.setState({name: true}) : this.setState({name: false});
  }

  validateEmail(el) {
    const email = el.value;
    const regexEmailVal = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    email.match(regexEmailVal) ? this.setState({email: true}) : this.setState({email: false});
  }

  validateContactNo(el) {
    const contactNo = el.value;
    const regexContactNo = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    contactNo.length > 9 && contactNo.match(regexContactNo) ? this.setState({contactNo: true}) : this.setState({contactNo: false});
  }

  validatePlacement(el) {
    const placement = el.value;

    placement && placement != "Select an option" ? this.setState({placement: true}) : this.setState({placement: false});
  }

  validateDate() {
    const dates = Array.from(document.getElementsByClassName('datesApplied'));
    let valid = false;

    dates.map((date) => {
      if (date.checked) {
        valid = true;
      }
    });
    this.setState({dates: valid})
  }

  validateAboutYou(el) {
    el.value.length > 0 ? this.setState({aboutYou: true}) : this.setState({aboutYou: false});
  }

  validateWishInvolved(el) {
    el.value.length > 0 ? this.setState({wishInvolved: true}) : this.setState({wishInvolved: false});
  }

  validateWhyComms(el) {
    el.value.length > 0 ? this.setState({whyComms: true}) : this.setState({whyComms: false});
  }

  validateSchemes() {
    const schemes = Array.from(document.getElementsByClassName('schemesApplied'));
    let valid = false;

    schemes.map((scheme) => {
      if (scheme.checked) {
        valid = true;
      }
    });
    this.setState({scheme: valid})
  }

  
  render() {
    return (
      <section className="application">
        <div className="content">
          <h1>Application form</h1>
        </div>
        <form id="applicationForm" onSubmit={this.handleSubmit} action="/php/submit.php" method="POST">
          <div className="application-eligible">
            <div className="content">
              <span>Are you eligible to work in the UK?</span>
              <div className="checkBox-radio-inline">
                <input onChange={this.eligibleTrue} id="eligible-yes" defaultChecked={this.state.eligible} type="radio" name="Eligible" value="1"/>
                <label htmlFor="eligible-yes">Yes</label>

                <input onChange={this.eligibleFalse} id="eligible-no" type="radio" name="Eligible" value="0"/>
                <label htmlFor="eligible-no">No</label>
              </div>
            </div>
          </div>
          
          <div className={`content form ${this.state.eligible === null ? 'eligible': ''} ${this.state.eligible === false ? 'not-eligible': 'eligible'}`}>
            <h2>Personal Details</h2>
            <label className={`${this.state.name === null ? '': ''}${this.state.name === false ? 'show-message': ''}`}>
              <span>Full Name</span>
              <span className="error">Please enter your name</span>
              <input onKeyUp={this.validator} data-validator="name" type="text" name="name" />
            </label>

            <label className={`${this.state.email === null ? '': ''}${this.state.email === false ? 'show-message': ''}`}>
              <span>Email Address</span>
              <span className="error">Please enter a valid email</span>
              <input type="text" onKeyUp={this.validator} data-validator="email" name="email" />
            </label>

            <label className={`${this.state.contactNo === null ? '': ''}${this.state.contactNo === false ? 'show-message': ''}`}>
              <span>Contact Number</span>
              <span className="error">Please enter your contact number</span>
              <input type="number" onKeyUp={this.validator} data-validator="contactNo" name="number" />
            </label>

            <label className={`select-label ${this.state.placement === null ? '': ''}${this.state.placement === false ? 'show-message': ''}`}>
              <span>How did you hear about the placement scheme?</span>
              <span className="error">Please select one</span>
              <select onChange={this.validator} data-validator="placement" name="placement">
                <option value="Select an option">Select an option</option>
                <option value="Word of Mouth">Word of Mouth </option>
                <option value="Google">Google</option>
                <option value="Linked In">Linked In</option>
                <option value="Social Media">Social Media</option>
                <option value="Havas Immersion Days">Havas Immersion Days</option>
                <option value="Other">Other</option>
                <option value="Colleague or Friend at Havas">Colleague or Friend at Havas</option>
                <option value="Havas Company Site">Havas Company Site</option>
              </select>
            </label>

            <span className={`${this.state.dates === null ? '': ''}${this.state.dates === false ? 'show-message': ''}`}>Select preferred intake dates</span>
            <span>Please note applications close 1 month prior to the cohort start date</span>
            <div className={`checkBox-radio-inline ${this.state.dates === null ? '': ''}${this.state.dates === false ? 'show-message': ''}`}>
              <div className="errorWrapper">
                <span className="error">Please select one</span>
              </div>
              <input id="JanuaryMarch" type="checkbox" onChange={this.validator} data-validator="dates" className="datesApplied" name="preferreddates[]" value="JAN-MAR"/>
              <label htmlFor="JanuaryMarch">January - March</label>

              <input id="AprilJune" type="checkbox" onChange={this.validator} data-validator="dates" className="datesApplied" name="preferreddates[]" value="APR-JUN"/>
              <label htmlFor="AprilJune">April - June</label>

              <input id="JulySeptember" type="checkbox" onChange={this.validator} data-validator="dates" className="datesApplied" name="preferreddates[]" value="JUL-SEP"/>
              <label htmlFor="JulySeptember">July - September</label>

              <input id="OctoberDecember" type="checkbox" onChange={this.validator} data-validator="dates" className="datesApplied" name="preferreddates[]" value="OCT-DEC"/>
              <label htmlFor="OctoberDecember">October - December</label>
            </div>

            <h2>About you</h2>
            <label className={`${this.state.aboutYou === null ? '': ''}${this.state.aboutYou === false ? 'show-message': ''}`}>
              <span>We want to connect with you, so tell us about yourself and why we would want to meet you.</span>
              <span className="error">Please fill in</span>
              <textarea onKeyUp={this.validator} data-validator="aboutYou" name="aboutyou" rows="10" cols="30"></textarea>
            </label>

            <label className={`${this.state.wishInvolved === null ? '': ''}${this.state.wishInvolved === false ? 'show-message': ''}`}>
              <span>What piece of work in our industry do you wish you have been involved in creating and why.</span>
              <span className="error">Please fill in</span>
              <textarea name="wishinvolved" onKeyUp={this.validator} data-validator="wishInvolved" rows="10" cols="30"></textarea>
            </label>

            <label className={`${this.state.whyComms === null ? '': ''}${this.state.whyComms === false ? 'show-message': ''}`}>
              <span>Why do you want to work in the communications world?</span>
              <span className="error">Please fill in</span>
              <textarea name="whycomms" onKeyUp={this.validator} data-validator="whyComms" rows="10" cols="30"></textarea>
            </label>
          
            <span className={`${this.state.scheme === null ? '': ''}${this.state.scheme === false ? 'show-message': ''}`}>Which scheme are you applying for?</span>
            <div className={`checkBox-radio-inline ${this.state.scheme === null ? '': ''}${this.state.scheme === false ? 'show-message': ''}`}>
              <div className="errorWrapper">
                <span className="error">Please select one</span>
              </div>
              <input id="account-manager" type="checkbox" name="scheme[]" onChange={this.validator} data-validator="schemes" className="schemesApplied" value="Acc"/>
              <label htmlFor="account-manager" >Account Management</label>

              <input id="strategey" type="checkbox" name="scheme[]" onChange={this.validator} data-validator="schemes" className="schemesApplied" value="Strat"/>
              <label htmlFor="strategey">Strategy</label>

              <input id="creative" type="checkbox" name="scheme[]" onChange={this.validator} data-validator="schemes" className="schemesApplied" value="Cr"/>
              <label htmlFor="creative">Creative</label>

              <input id="design" type="checkbox" name="scheme[]" onChange={this.validator} data-validator="schemes" className="schemesApplied" value="De"/>
              <label htmlFor="design">Design</label>

              <input id="production" type="checkbox" name="scheme[]" onChange={this.validator} data-validator="schemes" className="schemesApplied" value="Prod"/>
              <label htmlFor="production">Production</label>
            </div>

            <label>
              <span>Link to your portfolio (optional)</span>
              <input type="text" name="portfolio" />
            </label>

            <label>
              <span>Link to one piece of your own work you’re most proud of and the reason why. (optional)</span>
              <textarea name="work" rows="10" cols="30"></textarea>
            </label>
            <button className="button" onClick={this.handleSubmit} type="submit">Submit</button>
          </div>

          <div className={`content not-eligible-content ${this.state.eligible === null ? 'not-eligible': ''}${this.state.eligible === false ? '': 'not-eligible'}`}>
            <p>We are unable to legally employ you on our placement scheme if you do not have a current working visa for the UK. Please do reapply if your visa status changes.</p>
            <NavLink to="/">Please take me home</NavLink>
          </div>
        </form>
      </section>
    )
  }
}

export default Application
