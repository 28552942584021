import React from "react";
import {NavLink} from "react-router-dom";

const Error = () => {
  return (
    <section className="error404">
      <div className="content">
        <h1>
          Oops, wrong platform! <br/>
          Error 404
        </h1>
        <NavLink to="/">
          Please, take me home 
        </NavLink>
      </div>
    </section>
  );
};

export default Error;