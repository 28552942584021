import React, {Component} from 'react';

class Thankyou extends Component {

  render() {
    
    return (
      <section className="thankyou">
        <div className="content">
          <h1>Thank you for taking the time to apply.</h1>

          <p>I'd like to inform you that we have received your application.</p>
 
          <p>We are currently reviewing all applications and will keep you posted on the status of yours.</p>
           
          <p>Looking forward to continuing the journey with potential platformers.</p>
        </div>
      </section>
    )
  }
}

export default Thankyou;